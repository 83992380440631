.investment-card {
	position: relative;
	overflow: hidden;
}

.investment-card .new-label {
	position: absolute;
	top: 0px;
	right: 0px;
	border-top-right-radius: 10px;
	background-color: #00bfaa;
	font-size: 14px;
	color: #ffffff;
	text-transform: uppercase;
	padding: 0.5rem 0.75rem;
}

.investment-card .item {
	margin: 5px 0px;
}

.investment-card .item .label {
	font-size: 14px;
}

.investment-card .item .data {
	font-size: 16px;
	font-weight: 600;
}

.investment-card .item .collection-bar {
	display: flex;
	width: 209px;
	height: 6px;
	border-radius: 3px;
	background-color: #e2e2e4;
	position: relative;
	overflow: hidden;
}

.investment-card .item .collection-bar > div {
	position: absolute;
	top: 0px;
	left: 0px;
	height: 6px;
	width: 0%;
	background-color: #404040;
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}

.investment-card .footer {
	background-color: #f4f4f4;
}

.investment-card .footer > div > div > div {
	height: 34.8px;
}

.investment-card .footer button {
	font-weight: 600;
	font-size: 14px;
	padding-right: 0px;
}

.investment-card .footer .sign-now-btn {
	color: #7652ff;
}

.investment-card .footer .dismiss-btn {
	color: #ed4848;
}

.investment-card .footer .details-btn {
	color: #000000;
}

.investment-card .footer .details-btn:hover {
	opacity: 0.3;
}

.request-card-wrapper > div:first-child {
	transform: translateY(10px);
}

/* Start Investment Card Payment History Bar */
.investment-card .investment-card-payment-status-bar .section {
	width: 25px;
	height: 8px;
	border-right: 2px solid;
	background-color: #ffffff;
}

.investment-card .investment-card-payment-status-bar .section:first-of-type {
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}

.investment-card .investment-card-payment-status-bar .section:last-of-type {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-right: 0px;
}

.investment-card .investment-card-payment-status-bar .section-upcoming {
	background-color: #ffffff;
	border-right-color: #dfdfdf;
}

.investment-card .investment-card-payment-status-bar .section-missed {
	background-color: #ed4848;
	border-right-color: #721405;
}

.investment-card .investment-card-payment-status-bar .section-paid {
	background-color: #00bfaa;
	border-right-color: #008679;
}

.investment-card .investment-card-payment-status-bar .section-late {
	background-color: #f5a623;
	border-right-color: #b06405;
}

.investment-card .investment-card-payment-status-bar .section-current {
	background-color: #e82eff;
	border-right-color: #b82aca;
}

.investment-card .investment-card-payment-status-bar .section-initiated {
	background-color: #5d6aff;
	border-right-color: #3b45b6;
}
/* End Investment Card Payment History Bar */

/* Start Payment History Section  */
.payment-history-section .bullet {
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background-color: #d8d8d8;
}

.payment-history-section .bullet.Upcoming,
.payment-history-section .bullet.upcoming {
	background-color: #d8d8d8;
}

.payment-history-section .bullet.Missed,
.payment-history-section .bullet.missed {
	background-color: #ed4848;
}

.payment-history-section .bullet.Paid,
.payment-history-section .bullet.paid {
	background-color: #00bfaa;
}

.payment-history-section .bullet.Late,
.payment-history-section .bullet.late {
	background-color: #f5a623;
}

.payment-history-section .bullet.Current,
.payment-history-section .bullet.current {
	background-color: #e82eff;
}

.payment-history-section .bullet.Initiated,
.payment-history-section .bullet.initiated {
	background-color: #5d6aff;
}
/* End Payment History Section  */

.investment-modal .terms-and-conditions {
	white-space: pre-line;
	height: calc(100vh - 399px);
	overflow: auto;
}

.investment-modal .transfer-response-title {
	color: #454545;
	font-weight: 700;
}
