.boxes-wrapper .white-box {
	background-color: #ffffff;
	padding: 1rem 2.5rem;
	cursor: pointer;
	display: flex;
	max-width: 300px;
	align-items: center;

	border-radius: 5px;
	margin-bottom: 1rem;
	min-height: 130px;
}

.boxes-wrapper .white-box:last-of-type {
	margin-bottom: 0rem;
	border-bottom: none;
}

.boxes-wrapper .white-box > span {
	color: #4a4a4a;
	font-size: 14px;
	padding-right: 0.5rem;
}

.boxes-wrapper .white-box > img {
	margin-left: 2.5rem;
	margin-left: auto;
}

/* Custom for Support Page */
@media (max-width: 768px) {
	.support-page .boxes-wrapper .white-box {
		border-bottom: 1px solid #dee2e6;
		margin-bottom: 0rem;
		border-radius: 0px;
		min-height: 0px;
		padding: 1rem;
	}
}

/* Custom for Settings Page */
@media (max-width: 768px) {
	.settings-page .boxes-wrapper .white-box {
		min-height: 90px;
	}
}

@media (min-width: 769px) {
	.boxes-wrapper .white-box {
		border-radius: 5px;
		margin-bottom: 1rem;
		min-height: 130px;
		border-bottom: none;
	}
}

/* Start Modal */
@media (min-width: 576px) {
	.white-box-modal {
		max-width: 600px;
	}
}

.white-box-modal .modal-content {
	border-radius: 0px;
}

.white-box-modal .modal-header {
	border-bottom: none;
}

.white-box-modal .modal-header button {
	color: #7652ff;
	font-weight: 600;
}

.white-box-modal .modal-body {
	padding-right: 3rem;
	padding-left: 3rem;
}

.white-box-modal .modal-body .modal-title {
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 2rem;
}

.white-box-modal .modal-body .modal-text {
	white-space: pre-line;
}

@media (min-width: 768px) {
	.white-box-modal .modal-body .modal-text {
		max-height: calc(100vh - 334px);
		overflow: auto;
	}
}

.white-box-modal .modal-footer {
	justify-content: center;
	border-top: none;
	padding-bottom: 2rem;
}

.white-box-modal .modal-footer button {
	min-width: 240px;
}
/* End Modal */
