.mobile-nav {
	background-color: #131526;
	color: #ffffff;
}

.mobile-nav .title {
	font-weight: 600;
}

.mobile-nav .items > a {
	display: block;
	color: rgba(255, 255, 255, 0.3);
}

.mobile-nav .items > a:hover {
	text-decoration: none;
}

.mobile-nav .items > a.active {
	color: rgba(255, 255, 255, 1);
}
