.component {
	display: flex;
	justify-content: center;
	padding-top: 17vh;
}

/* Pre-login Form */
.pre-login-form {
	padding: 53px 125px;
}

.pre-login-form .logo img {
	width: 46px;
	height: 46px;
}

.pre-login-form .title {
	font-weight: bold;
	font-size: 30px;
}

@media (min-width: 320px)   {
	/* .pre-login-form {
		padding: 30px 16px;
	} */

	.pre-login-form .title {
		font-size: 20px;
	}
}

@media (min-width: 444px) {
	/* .pre-login-form {
		padding: 30px 30px;
	} */

	.pre-login-form .title {
		font-size: 25px;
	}
}

@media (min-width: 500px) {
	.pre-login-form {
		padding: 30px 60px;
	}

	.pre-login-form .title {
		font-size: 30px;
	}
}

@media (min-width: 767px) {
	.pre-login-form {
		padding: 53px 40px;
	}
}

/* Pre-login Form */

/* Start Card */
.card {
	border-radius: 10px;
	box-shadow: 0px 0px 15px rgba(19, 21, 38, 0.1);
	background-color: #fff;
	display: block;
	border: none;
}
/* End Card */

/* Start Scrollbar */
@media (min-width: 767px) {
	::-webkit-scrollbar {
		width: 4px;
	}

	::-webkit-scrollbar-thumb {
		background-color: #e2e2e2;
		border-radius: 20px;
	}
}
/* End Scrollbar */

/* Start Modal */
@media (min-width: 576px) {
	.general-modal {
		max-width: 600px;
	}
}

.general-modal .modal-content {
	border-radius: 0px;
}

.general-modal .modal-header {
	border-bottom: none;
}

.general-modal .modal-header button {
	color: #7652ff;
	font-weight: 600;
}

.general-modal .modal-body {
	padding-right: 4rem;
	padding-left: 4rem;
}

.general-modal .modal-body .modal-left-space-container {
	margin-left: 77px;
}

.general-modal .modal-body .modal-title {
	font-weight: 600;
	font-size: 24px;
	margin-bottom: 2rem;
}

.general-modal .modal-body .modal-text {
	white-space: pre-line;
	max-height: calc(100vh - 334px);
	overflow: auto;
}

.general-modal .modal-body .modal-image {
	display: flex;
	justify-content: center;
	margin-top: 3rem;
}

.general-modal .modal-buttons-wrapper {
	padding: 1rem 0rem;
	margin-top: 2rem;
}

.general-modal .modal-buttons-wrapper.horizontal {
	display: flex;
	justify-content: center;
}

.general-modal .modal-buttons-wrapper.horizontal button {
	width: 50%;
}

.general-modal .modal-buttons-wrapper.horizontal button:nth-child(2) {
	margin-left: 1rem;
}

.general-modal .modal-buttons-wrapper.vertical button:nth-child(2) {
	margin-top: 1rem;
}

.general-modal .modal-footer {
	justify-content: center;
	border-top: none;
	padding-bottom: 2rem;
}

.general-modal .modal-footer button {
	min-width: 240px;
}

@media (max-width: 576px) {
	.general-modal .modal-body .modal-left-space-container {
		margin-left: 0px;
	}
}
/* End Modal */
