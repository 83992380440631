.owner-card {
	background-color: #fff;
	border-radius: 0.32rem;
}

.owner-card .square {
	font-size: 43px;
	line-height: 15px;
	color: #d8d8d8;
}
