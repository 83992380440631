.feed-item {
	padding: 0.4rem 1rem;
	border-bottom: 0.5px solid #c5c5c5;
}

.feed-item .badge-secondary {
	background-color: #c5c5c5;
	border-radius: 3px;
	padding: 0.3em 0.6em;
	font-size: 12px;
}

.feed-item .time {
	color: #c5c5c5;
	font-size: 12px;
}
