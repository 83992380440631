.floating-label {
	position: relative;
}

.floating-label .floating-input:focus,
.floating-label .floating-select:focus {
	outline: none;
}

.floating-label label {
	color: rgba(0, 0, 0, 0.471439);
	font-size: 16px;
	font-weight: normal;
	position: absolute;
	pointer-events: none;
	left: 16px;
	top: 20px;
	margin-bottom: 0px;
	transition: 0.1s ease all;
	-moz-transition: 0.1s ease all;
	-webkit-transition: 0.1s ease all;
}

.floating-label .floating-input:focus ~ label,
.floating-label .floating-input:not(:placeholder-shown) ~ label {
	top: 7px;
	font-size: 10px;
}

.floating-label .floating-select:focus ~ label,
.floating-label .floating-select:not([value=""]):valid ~ label {
	top: 7px;
	font-size: 10px;
}

/* active state */
.floating-label .floating-input:focus ~ .bar:before,
.floating-label .floating-input:focus ~ .bar:after,
.floating-label .floating-select:focus ~ .bar:before,
.floating-label .floating-select:focus ~ .bar:after {
	width: 50%;
}

.floating-label .floating-textarea {
	min-height: 30px;
	max-height: 260px;
	overflow: hidden;
	overflow-x: hidden;
}

.floating-label .floating-password-input {
	padding-right: 50px;
}

.floating-label .show-password-button {
	background-color: transparent;
	border: none;
	outline-style: none;
	position: absolute;
	top: 17px;
	right: 11px;
}
