/* Start Get Started Page */
.get-started-page {
	max-width: 320px;
}

.get-started-page .title {
	font-size: 22px;
	font-weight: 800;
}

.get-started-page .message {
	font-size: 14px;
	margin-top: 2rem;
}

.get-started-page .image {
	display: block;
	margin-top: 2rem;
}

.get-started-page button {
	margin-top: 2rem;
}

@media (max-width: 767px) {
	.get-started-page {
		margin: auto;
		text-align: center;
	}

	.get-started-page .image {
		width: 200px;
		margin: auto;
		margin-top: 2rem;
	}

	.get-started-page button {
		margin-top: 4rem;
	}
}

@media (max-width: 576px) {
	.get-started-page {
		text-align: left;
	}

	.get-started-page .message {
		margin-top: 1rem;
	}

	.get-started-page .image {
		margin-top: 2rem;
	}
}
/* End Get Started Page */

.page-title {
	font-size: 24px;
	font-weight: 800;
	color: #000000;
}

/* Start Navbar */
.params-nav {
	display: flex;
}

.params-nav a {
	flex-grow: 1;
	color: #9b9b9b;
	font-weight: 600;
	font-size: 14px;
	border-bottom: 1px solid #000000;
	padding-top: 5px;
	padding-bottom: 5px;
	text-align: center;
}

.params-nav a:hover {
	text-decoration: none;
}

.params-nav a.active {
	color: #000000;
	border-bottom: 4px solid #5d6aff;
}
/* End Navbar */

/* Start Zero State */
.zero-state-title {
	font-weight: 600;
	font-size: 20px;
	padding-bottom: 1.5rem;
}

.zero-state-section p {
	max-width: 380px;
}

.zero-state-section p > div {
	margin-top: 1.5rem;
}

.zero-state-section .image-container {
	text-align: center;
	margin-top: 1.5rem;
}

.zero-state-section .image-container img {
	width: 250px;
}

@media (min-width: 576px) {
	.zero-state-title {
		text-align: center;
	}

	.zero-state-section a,
	.zero-state-section button {
		max-width: 350px;
	}
}

@media (min-width: 769px) {
	.zero-state-title {
		text-align: left;
	}

	.zero-state-section .image-container {
		text-align: left;
	}
}
/* End Zero State */

/* Start Loans Container */
.loans-container {
	border-bottom: 1px solid #000000;
	margin-bottom: 1.5rem;
}

.loans-container:last-of-type {
	border-bottom: none;
	margin-bottom: 0px;
}

.loans-container .title {
	font-size: 14px;
	font-weight: 600;
	color: #000000;
}

.loans-container .see-more-loans-link {
	text-align: right;
	font-size: 14px;
	padding: 1rem 0rem;
}

.loans-container .see-more-loans-link a {
	color: #4b4b4b;
}

.loans-container .see-more-loans-link a:hover {
	text-decoration: none;
}

/* End Loans Container */
