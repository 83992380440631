.signup-process-form.link-bank {
	width: 100%;
}

.signup-process-form .form-header {
	max-width: 500px;
}

.signup-process-form .buttons-wrapper {
	width: 360px;
}

.link-bank-cards-container {
	margin-left: -15px;
	margin-right: -15px;
}

.link-bank-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	height: 75px;
}

.link-bank-card:hover {
	box-shadow: 0 0.75rem 0.75rem rgba(0, 0, 0, 0.09);
}

.link-bank-card img {
	max-width: 80%;
	max-height: 100%;
}

@media (min-width: 769px) {
	.link-bank-card {
		height: 134px;
	}
}

.white-search-bar {
	position: relative;
	margin-bottom: 1rem;
}

.white-search-bar input {
	padding: 1rem 3rem;
	border: none;
	border-radius: 0.44rem;
	width: 100%;
	outline-style: none;
}

.white-search-bar img {
	position: absolute;
	top: 20px;
	left: 16px;
}

@media (min-width: 769px) {
	.white-search-bar input {
		padding: 1rem 4.5rem;
	}

	.white-search-bar img {
		left: 49px;
	}
}
