/* Start Content */
.dashboard-wrapper {
	position: relative;
	overflow: hidden;
	height: calc(100vh - 60px);
}

.dashboard-content {
	position: relative;
	height: calc(100vh - 60px);
	overflow: auto;
}

@media (max-width: 576px) {
	.mobile-nav + .dashboard-content {
		height: calc(100vh - 156px);
	}
}

@media (min-width: 320px) {
	.dashboard-content {
		padding: 1rem;
	}
}

@media (min-width: 576px) {
	.dashboard-content {
		padding: 2.5rem 1rem;
	}
}

@media (min-width: 720px) {
	.dashboard-content {
		padding-right: 80px;
		padding-left: 80px;
	}
}

@media (min-width: 769px) {
	.dashboard-content {
		padding-right: 16px;
		padding-left: 16px;
	}
}

@media (min-width: 1024px) {
	.dashboard-content {
		padding-right: 80px;
		padding-left: 80px;
	}
}

@media (min-width: 1295px) {
	.dashboard-content {
		padding-right: 400px;
		padding-left: 80px;
	}
}
/* End Content */

.analysis-card-desktop .current-balance > span:first-child,
.analysis-card-desktop .current-balance > span:nth-child(2) {
	font-size: 15px;
	color: #000000;
	font-weight: bold;
}

.analysis-card-desktop .current-balance > span:last-child {
	font-size: 36px;
	color: #000000;
	font-weight: bold;
}

.analysis-card-desktop .label {
	color: #4b4b4b;
	font-size: 12px;
	font-weight: 400;
}

.analysis-card-desktop .value {
	color: #4b4b4b;
	font-size: 15px;
	font-weight: 600;
}

.analysis-card-desktop .value > a {
	color: #4b4b4b;
}

.analysis-card-tablet .background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 272px;
	background-color: #131526;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.analysis-card-tablet .current-balance > span:first-child,
.analysis-card-tablet .current-balance > span:nth-child(2) {
	font-size: 15px;
	color: #ffffff;
	font-weight: bold;
}

.analysis-card-tablet .current-balance > span:last-child {
	font-size: 36px;
	color: #ffffff;
	font-weight: bold;
}

.analysis-card-tablet .label {
	color: #ffffff;
	font-size: 12px;
	font-weight: 400;
}

.analysis-card-tablet .value {
	color: #ffffff;
	font-size: 15px;
	font-weight: 600;
}

.analysis-card-tablet .value > a {
	color: #ffffff;
}

.analysis-card-mobile .background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 216px;
	background-color: #131526;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.analysis-card-mobile .current-balance > span:first-child,
.analysis-card-mobile .current-balance > span:nth-child(2) {
	font-size: 15px;
	color: #ffffff;
	font-weight: bold;
}

.analysis-card-mobile .current-balance > span:last-child {
	font-size: 36px;
	color: #ffffff;
	font-weight: bold;
}

.analysis-card-mobile .label {
	color: #4b4b4b;
	font-size: 12px;
	font-weight: 400;
}

.analysis-card-mobile .value {
	color: #4b4b4b;
	font-size: 15px;
	font-weight: 600;
}

.analysis-card-mobile .value > a {
	color: #4b4b4b;
}

.analysis-card-mobile .balance-container .label,
.analysis-card-mobile .balance-container .value {
	color: #ffffff;
}

.analysis-card-mobile .lenme-cash .label {
	color: #000000;
	font-weight: bold;
}

.analysis-card-mobile .lenme-cash .value {
	color: #000000;
	font-weight: bold;
}

.analysis-card-mobile .lenme-cash .value > a {
	color: #000000;
}

/* Start Nav Tabs */
.dashboard-nav-tabs .params-nav a {
	font-size: 16px;
}

@media (max-width: 576px) {
	.dashboard-nav-tabs .params-nav {
		width: 50%;
	}

	.dashboard-nav-tabs .params-nav > a {
		border: none !important;
		color: #4a4a4a;
		font-weight: 400;
		font-size: 18px;
		padding: 7px 0px;
	}

	.dashboard-nav-tabs .params-nav > .active {
		font-weight: 600;
	}

	.dashboard-nav-tabs .filter-icon {
		position: absolute;
		top: 0px;
		right: 0px;
		border-radius: 50%;
		background-color: #fff;
		width: 40px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		user-select: none;
	}
}
/* End Nav Tabs */

.dashboard-loading-dots {
	width: 50px;
}

/* Start Fixed Filter */
@media (min-width: 576px) {
	.dashboard-filter {
		position: relative;
	}

	.dashboard-filter > form {
		position: sticky;
		top: -1.5rem;
	}
}
/* End Fixed Filter */
