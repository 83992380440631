.show-more {
	margin-top: 1.5rem;
	display: flex;
	justify-content: flex-end;
}

.show-more .btn {
	color: #4a4a4a;
	font-weight: bold;
	padding-right: 0px;
}
