.header {
	position: relative;
	height: 60px;
	background-color: white;
	border-bottom: 1px solid #dfdfdf;
}

.header .header-btn {
	background-color: transparent;
	border: none;
	outline: none;
	border-left: 1px solid #dfdfdf;
	height: 59px;
	padding-left: 28px;
	padding-right: 28px;
	display: flex;
	align-items: center;
}

.header .header-btn:hover {
	background-color: #fcfcfc;
	text-decoration: none;
}

.header .header-btn:active {
	background-color: #efefef !important;
}

.header .header-btn span {
	margin-left: 0.5rem;
}

.header .menu-btn {
	background-color: transparent;
	border: 0px;
}

.header .menu-btn:focus {
	outline-style: none;
}

.header .mid-logo {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
/* Start Notification */
.header .notification-header-btn {
	padding: 0px;
	border-radius: 0px;
	background-color: transparent;
	border: none;
	outline: none;
}

.header .notification-header-btn:active {
	border-color: #dfdfdf !important;
}

.header .notification-popup {
	border: 0px;
	border-radius: 10px;
	min-width: 390px;
	box-shadow: 0px 0px 15px rgba(19, 21, 38, 0.1);
	max-height: 500px;
	overflow: auto;
}

.header .notification-popup .zero-state > div:first-child {
	font-weight: 600;
	font-size: 17px;
	margin-bottom: 10px;
}

.header .notification-popup .zero-state > div:last-child {
	font-size: 14px;
	color: #4b4b4b;
}

.header .notification-popup a {
	display: block;
}

.header .notification-popup a:hover {
	text-decoration: none;
}

.header .notification-popup .single-item a {
	color: #000000;
	font-weight: 600;
	font-size: 14px;
}

.header .notification-popup .see-all {
	margin-top: 1rem;
	font-weight: 600;
	font-size: 1rem;
	text-align: center;
}

.header .notification-popup .see-all a {
	color: #7652ff;
}
/* End Notification */

.header .user-header-btn img {
	width: 32px;
	height: 32px;
	margin-right: 0.75rem;
}

@media (min-width: 578px) {
	.header .user-header-btn.active {
		background-color: #131526;
		color: #ffffff;
	}
}

.header .user-header-btn span {
	color: #4a4a4a;
	font-weight: 600;
	font-size: 14px;
}

.header .user-header-btn.active span {
	color: #ffffff;
}

@media (max-width: 576px) {
	.header .header-btn {
		border-left: none;
		padding: 0px;
	}

	.header .header-btn:active,
	.header .header-btn:hover {
		background-color: transparent;
	}
}

@media (min-width: 769px) {
	.header .menu-btn {
		display: none;
	}
}

@media (max-width: 768px) {
	.header .left-logo {
		display: none;
	}
}
