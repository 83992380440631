.borrower-info-card {
	font-size: 14px;
	font-weight: 600;
}

.request-sub-title {
	font-size: 12px;
	font-weight: normal;
	color: #646464;
}

.card-item {
	width: 100%;
	flex: 0 50%;
}

.vantage-score {
	font-size: 12px;
	font-weight: normal;
	color: #a0a0a0;
}
