@media (min-width: 1295px) {
	.support-page .dashboard-content {
		padding-right: 100px;
		padding-left: 80px;
	}
}

.support-page .search-bar-wrapper {
	display: flex;
	align-items: center;
	background-color: #ffffff;
	padding-left: 1rem;
	padding-right: 1rem;
	border-radius: 5px;
}

@media (min-width: 769px) {
	.support-page .search-bar-wrapper {
		padding-left: 2.5rem;
		padding-right: 1.5rem;
	}
}

.support-page .search-bar-wrapper .form-group {
	margin-bottom: 0;
	flex-grow: 1;
}

.support-page .search-bar-wrapper input,
.support-page .search-bar-wrapper input:focus {
	border: 0px;
}

.support-page .boxes-wrapper .title {
	color: #4b4b4b;
	font-weight: 600;
	margin-top: 2rem;
	margin-bottom: 0.5rem;
}

.support-page .boxes-wrapper .white-box {
	max-width: 100%;
}
