.toast-container {
	position: fixed;
	bottom: 1rem;
	left: 1rem;
}

.ln-toast {
	width: 340px;
	color: #ffffff;
	background-color: #131526;
}

.ln-toast:not(:last-of-type) {
	margin-bottom: 1rem;
}

.ln-toast .ln-toast-label img {
	width: 15px;
}

.ln-toast .ln-toast-title {
	font-size: 12px;
	font-weight: 600;
}

.ln-toast .ln-toast-body {
	font-size: 12px;
}

.ln-toast .ln-toast-btn {
	color: #ffffff;
	text-transform: capitalize;
	font-weight: bold;
	background-color: transparent;
	border: none;
	font-size: 15px;
	padding: 5px 16px 5px 0px;
	outline-style: none;
}

.ln-toast .ln-toast-close {
	border-left: 0.5px solid #ffffff;
	cursor: pointer;
}

@media (max-width: 576px) {
	.ln-toast {
		width: 288px;
	}
}

/* Toast Colors */
.ln-toast-info {
	background-color: #7652ff;
}

.ln-toast-success {
	background-color: #01cd9d;
}

.ln-toast-danger {
	background-color: #ed4848;
}

.ln-toast-warning {
	background-color: #f5a623;
}
