.info-card {
	position: relative;
	border-left-width: 3px;
	border-radius: 5px;
	border-left-style: solid;
}

.info-card-label {
	font-size: 12px;
	font-weight: 300;
	color: #404040;
}

.info-card-value {
	font-size: 16px;
	font-weight: bold;
	color: #454545;
}

.info-card-info-icon {
	position: absolute;
	top: 10px;
	right: 8px;
}
