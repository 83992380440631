.request {
	position: relative;
	font-size: 14px;
	font-weight: 600;
}

.request-card-wrapper {
	align-items: baseline;
}

.request-sub-title {
	font-size: 12px;
	font-weight: normal;
	color: #646464;
}

.card-item {
	width: 100%;
	flex: 0 50%;
}

.btn-offer {
	width: min-content;
	font-weight: 600;
	color: rgba(0, 0, 0, 1);
}

.btn-offer:hover {
	color: rgba(0, 0, 0, 0.3);
}

.btn-dismiss {
	width: min-content;
	font-weight: 600;
	color: rgba(237, 72, 72, 1);
}

.btn-dismiss:hover {
	color: rgba(237, 72, 72, 0.3);
}

.vantage-score {
	font-size: 12px;
	font-weight: normal;
	color: #a0a0a0;
}

.annual-interest {
	font-size: 16px;
	font-weight: bold;
	color: #7652ff;
}

.expected-earning {
	font-size: 12px;
	font-weight: normal;
	color: #4b4b4b;
}

.interest-rate {
	font-size: 20px;
	font-weight: 600;
	color: #4a4a4a;
}

.request-info-card {
	flex: 1 50%;
}

.financial-health-title {
	font-size: 12px;
	font-weight: 300;
	color: #404040;
}

.financial-health-fact {
	flex: 1 50%;
	text-align: center;
}

.financial-health-info {
	font-size: 12px;
	font-weight: 600;
	text-decoration: underline;
	color: #568ffc;
}

.info-card-close {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 15px;
	font-weight: bold;
	color: #7652ff;
}

.info-card-header {
	font-size: 16px;
	font-weight: bold;
	color: #404040;
}

.info-card-button {
	width: 50%;
}

.loading-spinner-offers {
	width: 40px;
	height: 20px;
}

.data-aggregation-graph p {
	height: 28px;
	padding-top: 5px;
	padding-bottom: 1rem;
	margin: 0px;
}

/* Lenme Predict Start */
.lenme-predict .bars > img {
	width: 100%;
}

.lenme-predict .bars > div {
	text-align: right;
	margin-top: 1rem;
	color: #898787;
	text-transform: uppercase;
	font-size: 12px;
	font-weight: 400;
}

.lenme-predict .footer {
	min-height: 42px;
}

.lenme-predict-locked {
	background-color: #d9d9d9;
}

.purchase-lenme-predict .custom-control {
	padding-left: 2rem;
	margin-bottom: 1rem;
}

.purchase-lenme-predict .custom-control-label::before {
	left: -2rem;
}

.purchase-lenme-predict .custom-radio .custom-control-label::after {
	left: -1.9rem;
}

.purchase-lenme-predict .custom-control-input:checked ~ .custom-control-label::before {
	border-color: #000000;
}

.purchase-lenme-predict .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABAklEQVQ4T62TzVEDMQyFJRqgBbwVbAfIFSR0Agd7hhPL1T4QOkgJSQURFbAVeF3CcocV44w3YwKZ/Cy+SbY/6WmeEPbODbn6CnAmAAQAdb5uEYAHkHVk25ZfsAwq8veBzcs+dIwV+V5Amsj2dcztAIr8qmMzO/T5ZyG3CmzvUm4LOFb5L2hF/iGwWWDSHNm+n1K5fJPlaKzIN4HN07mA3PkzKu2429jbSwBKuzestOvDxl5fBCDfTwJU2n38g4SpQ9xaF4HPnUNqf5CverqRCp+fbGVFft2xme+sPEKSpQGlOSQntQ2CyXiLX8s0JvI6zwWFMK+zALQoyAN8LiM/xtIz343rgBzSpmPHAAAAAElFTkSuQmCC);
}

.purchase-lenme-predict .custom-control-input[disabled] ~ .custom-control-label::before,
.custom-control-input:disabled ~ .custom-control-label::before {
	background-color: #fff;
}
/* Lenme Predict End */

/* Request Card Dialog Start */
@media (max-width: 576px) {
	.request-card-dialog .modal-body {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.annual-interest {
		font-size: 12px;
	}
}
/* Request Card Dialog End */
