select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    outline-style: none;
}

.select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    outline-style: none;
    background-color: #FFFFFF;
    background-image: url(../../../img/icons/blue/arrow-down.png) !important;
    background-repeat: no-repeat !important;
    background-position: calc(100% - 16px) 54% !important;
    background-size: 14px !important;
    padding-right: 33px;
    overflow: hidden;
}