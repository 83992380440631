.notification-item {
	background-color: #fff;
	padding: 1.5rem;
	box-shadow: 0px 0px 10px rgba(207, 207, 207, 0.0529608);
	border-radius: 5px;
	max-width: 560px;
}

.notification-item .new-label {
	font-weight: bold;
	margin-bottom: 5px;
	text-align: right;
	color: #30cab9;
}

.notification-item > div:nth-child(2) > div:last-of-type {
	flex-grow: 1;
}

.notification-item .notification-direction-link {
	color: #7652ff;
	font-weight: 600;
}
