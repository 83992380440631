.custom-radio .custom-control-label::before {
    top: 0.15rem;
	width: 20px;
    height: 20px;
    border-width: 1.5px;
}

.custom-radio .custom-control-label::after {
    width: 16px;
    height: 16px;
    top: 0.27rem;
    left: -1.4rem;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #ffffff;
    border-width: 1.5px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background: no-repeat 50% 50%/ 73% 73%;
    background-image: url(../../../img/icons/blue/radio.png);
}
