.notifications-page > .title {
	font-weight: 600;
	font-size: 24px;
}

.notifications-page .zero-state-image {
	margin-top: 99px;
}

.notification-section {
	margin-top: 2rem;
}

.notification-section title {
	color: #4b4b4b;
}

@media (max-width: 767px) {
	.notifications-page .zero-state-image {
		margin-top: 70px;
	}

	.notifications-page .zero-state-image img {
		width: 150px;
	}
}

@media (max-width: 576px) {
	.notifications-page .zero-state-image {
		margin-top: 70px;
		text-align: center;
	}

	.notifications-page .zero-state-image img {
		width: 200px;
	}
}
