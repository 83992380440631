.avatar {
	width: 39.61px;
	height: 39.61px;
}

.avatar-initials {
	display: flex;
	justify-content: center;
	flex-direction: column;
	text-align: center;
	position: absolute;
	top: 31px;
	width: 37px;
	height: 16px;
	background: #000000;
	color: #ffffff;
	font-size: 10px;
	font-weight: 900;
	border-radius: 8px;
}

.double-avatars {
	margin: 1rem;
	width: 60px;
	height: 39.61px;
}

.double-avatars img {
	position: absolute;
	width: 39.61px;
	height: 39.61px;
}

.avatar-double-1 {
	left: 0;
}

.avatar-double-2 {
	right: -2px;
}

.small-avatars {
	margin: 0.5rem;
	width: 40px;
	height: 25px;
}

.small-avatars img {
	width: 25px;
	height: 25px;
}
