.was-validated .form-control:invalid,
.form-control.is-invalid {
	padding-right: 1rem;
	background-image: unset;
}

.form-control.form-control-optional {
	padding-right: 84px;
}

.form-group .optional {
	position: absolute;
	top: 24px;
	right: 1rem;
	color: #5d6aff;
	font-style: italic;
	font-size: 13px;
	user-select: none;
}

/* Button */
.btn-lg {
	font-weight: 600;
}

.btn-white {
	color: #7984ff;
	background-color: #ffffff;
	border-color: #ffffff;
	font-weight: 400;
}

.btn-white:hover {
	color: #7984ff;
	background-color: #ffffff;
	border-color: #ffffff;
	font-weight: 400;
}

.btn-icon-text img {
	margin-right: 0.5rem;
	height: 16px;
}

.btn-outline-white {
	color: #4846ff;
	border: 1px solid #4846ff;
	background-color: #ffffff;
	font-weight: 400;
}

.btn-outline-white:hover {
	color: #4846ff;
	border: 1px solid #4846ff;
	background-color: #ffffff;
	font-weight: 400;
}

.btn-black {
	color: #ffffff;
	background-color: #000000;
	border-color: #000000;
	font-weight: 400;
}

.btn-black:hover {
	color: #ffffff;
	background-color: #000000;
	border-color: #000000;
	font-weight: 400;
}

.btn-outline-black {
	color: #000000;
	background-color: #ffffff;
	border-color: #000000;
	font-weight: 400;
}

.btn-outline-black:hover {
	color: #000000;
	background-color: #ffffff;
	border-color: #000000;
	font-weight: 400;
}
/* Button */

/* No Gutters */
@media (max-width: 576px) {
	.mobile-no-gutters {
		margin-right: 0;
		margin-left: 0;
	}

	.mobile-no-gutters > .col,
	.mobile-no-gutters > [class*="col-"] {
		padding-right: 0;
		padding-left: 0;
	}
}
/* No Gutters */

/* Text */
.text-purple {
	color: #bd2eff;
}
