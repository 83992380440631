.profile-welcome-text {
	font-size: 20px;
	font-weight: 600;
	color: #000000;
}

.profile-section {
	position: relative;
	padding: 2rem 0rem;
	border-bottom: 1px solid #dfdfdf;
}

@media (max-width: 767px) {
	.profile-section {
		padding: 1rem 0rem;
	}
}

.profile-section-title {
	font-size: 22px;
	font-weight: bold;
	color: #4b4b4b;
	margin-bottom: 1.5rem;
}

.profile-section-verified {
	font-size: 12px;
	color: #141c7a;
}

.profile-section .info-wrapper {
	margin: 1rem 0rem;
}

.profile-section .info-wrapper .label {
	font-size: 12px;
	font-weight: bold;
}

.profile-section .info-wrapper .value .link {
	color: #4846ff;
	text-decoration: underline;
}

.profile-section .edit-button {
	position: absolute;
	bottom: 32px;
	right: 0;
}

.profile-page-link {
	color: #131526;
	font-weight: 600;
	font-size: 15px;
	margin-right: 0.5rem;
	text-decoration: underline;
	cursor: pointer;
}

.profile-page-link-name {
	color: #131526;
	font-size: 15px;
}

.profile-page-arrow {
	margin-right: 0.5rem;
}

/* Start Complete Profile Alert */
.complete-profile-alert {
	background-color: transparent;
}

.complete-profile-alert .title {
	color: #4846ff;
	font-weight: bold;
}

.complete-profile-alert .message {
	color: #4846ff;
	font-size: 14px;
}

.complete-profile-alert button {
	background-color: #4846ff;
	color: #ffffff;
}

.complete-profile-alert button:hover {
	background-color: #3a36fd;
	color: #ffffff;
}
/* End Complete Profile Alert */
