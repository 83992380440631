.sidebar .overlay {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.74);
	width: 100%;
	z-index: 1000;
}

.sidebar .items {
	display: flex;
	flex-direction: column;
	width: 260px;
	min-width: 260px;
	max-width: 260px;
	background-color: #131526;
	padding: 38px 0px;
	overflow: auto;
	height: calc(100vh - 60px);
}

.sidebar .sidebar-item {
	color: #ffffff;
	padding: 20px 40px;
	font-size: 14px;
	text-decoration: none;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;
}

.sidebar .sidebar-item:last-of-type {
	margin-top: auto;
}

.sidebar .sidebar-item:hover {
	background-color: #383946;
}

.sidebar .sidebar-item:active {
	background-color: #2e2f3a;
}

.sidebar .sidebar-item.active {
	background-color: #424351;
}

.sidebar .sidebar-item img {
	margin-right: 20px;
}

.sidebar .close {
	display: none;
}

.sidebar .sidebar-section ul {
	list-style: none;
	padding-left: 0rem;
	margin-bottom: 0rem;
}

.sidebar .sidebar-section ul li a {
	padding-left: 5rem;
}

/* Tablet */
@media (max-width: 767px) {
	.sidebar .overlay {
		display: block;
	}

	.sidebar .items {
		position: absolute;
		top: 0px;
		left: 0px;
		bottom: 0px;
		height: 100vh;
		z-index: 1001;
		padding-top: 90px;
	}

	.sidebar .close {
		display: block;
		position: absolute;
		top: 15px;
		left: 15px;
	}
}
