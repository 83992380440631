/* Start content */
.bank-transfer-wrapper {
	position: relative;
	overflow: hidden;
	/* height: calc(100vh - 60px); */
}

.bank-transfer-content {
	position: relative;
	padding: 2.5rem 3rem;
	overflow: auto;
}

@media (min-width: 320px) {
	.bank-transfer-content {
		padding-right: 1rem;
		padding-left: 1rem;
		height: calc(100vh - 116px);
	}
}

@media (min-width: 576px) {
	.bank-transfer-content {
		padding-right: 16px;
		padding-left: 16px;
		height: calc(100vh - 60px);
	}
}

@media (min-width: 720px) {
	.bank-transfer-content {
		padding-right: 80px;
		padding-left: 80px;
	}
}

@media (min-width: 768px) {
	.bank-transfer-content {
		padding-right: 16px;
		padding-left: 16px;
	}
}

@media (min-width: 1024px) {
	.bank-transfer-content {
		padding-right: 80px;
		padding-left: 80px;
	}
}

@media (min-width: 1295px) {
	.bank-transfer-content {
		padding-right: 25%;
		padding-left: 80px;
	}
}
/* End content */

.bank-transfer-header {
	font-size: 24px;
	font-weight: 600;
}

.bank-transfer-fastlink-container {
	height: calc(100vh - 60px);
}

@media (min-width: 650px) {
	.modal-dialog.bank-transfer-modal {
		max-width: 575px;
		margin: 1.75rem auto;
	}
}

.bank-transfer-modal .buttons-wrapper {
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 1rem;
}

.bank-transfer-modal .buttons-wrapper > .btn {
	margin-top: 1rem;
}

@media (max-width: 576px) {
	.bank-transfer-modal .buttons-wrapper {
		flex-direction: column-reverse;
	}

	.bank-transfer-modal .buttons-wrapper > .btn {
		width: 100%;
	}
}

.modal-close {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 15px;
	font-weight: bold;
	color: #7652ff;
}

.bank-transfer-link-success-title {
	font-size: 25px;
	font-weight: bold;
}

.bank-link-success-button {
	width: 240px;
}

.bank-transfer-title {
	font-weight: 600;
	font-size: 24px;
	color: #000000;
}

.bank-transfer-subtitle {
	font-size: 14px;
	font-weight: bold;
	color: #4b4b4b;
}

.bank-transfer-balance-wrapper {
	font-weight: bold;
	font-size: 20px;
}

.bank-transfer-balance {
	font-size: 30px;
}

.funding-resource-card {
	height: 200px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #242424;
	box-shadow: 0px 0px 10px rgba(207, 207, 207, 0.0529608);
	border-radius: 5px;
	text-align: center;
}

.funding-resource-card:hover {
	border: 2px solid #040d6f;
}

.funding-resource-card-title {
	font-weight: 600;
}

.funding-resource-card-bank-name {
	font-size: 14px;
}

.funding-resource-card-status {
	color: #131526;
}

.primary-bank-text {
	color: #040d6f;
	font-size: 14px;
	font-weight: 600;
}

.bank-transfer-button {
	width: 240px;
	color: black;
}

.transfer-disclaimer {
	font-size: 14px;
	color: #e74646;
}

.bank-transfer-popup {
	box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.0647079), 0px -2px 4px rgba(0, 0, 0, 0.0374491);
	border-radius: 19px;
}

.bank-transfer-popup-input {
	width: 100%;
}

.bank-transfer-popup-input .form-control {
	border: 0;
}

.bank-transfer-popup-input .form-group {
	margin-bottom: 0;
}

.bank-transfer-popup-input .input-group {
	width: 100%;
}

.bank-transfer-popup-seperator {
	margin: 0;
}

.bank-transfer-popup-name {
	padding-left: 0.75rem;
}

.back-button {
	text-decoration-line: underline;
	color: #7652ff;
	font-size: 16px;
	font-weight: 600;
}

.form-header .main-form-label {
	font-size: 24px;
}

.previous-bank-results {
	position: absolute;
	left: 0;
	color: #7652ff;
	font-size: 18px;
}

.next-bank-results {
	position: absolute;
	right: 0;
	color: #7652ff;
	font-size: 18px;
}

.bank-transfer-popup {
	box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.0647079), 0px -2px 4px rgba(0, 0, 0, 0.0374491);
	border-radius: 19px;
}

.bank-transfer-popup-input {
	width: 100%;
}

.bank-transfer-popup-input .form-control {
	border: 0;
}

.bank-transfer-popup-input .form-group {
	margin-bottom: 0;
}

.bank-transfer-popup-input .input-group {
	width: 100%;
}

.bank-transfer-popup-seperator {
	margin: 0;
}

.bank-transfer-popup-name {
	padding-left: 0.75rem;
}

.form-header .main-form-label {
	font-size: 24px;
}

.previous-bank-results {
	position: absolute;
	left: 0;
	color: #7652ff;
	font-size: 18px;
}

.next-bank-results {
	position: absolute;
	right: 0;
	color: #7652ff;
	font-size: 18px;
}

.bank-transfer-cancel-button {
	color: #ed4848;
}
