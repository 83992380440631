.user-home-page-container {
	display: flex;
	background-color: #f8f9ff;
	overflow: hidden;
	background-image: url(../../img/assets/oval.svg);
	background-size: 100% 600px;
	background-position: 0 -250px;
	background-repeat: no-repeat;
	min-height: calc(100vh - 60px);
}

.user-home-page-container .body {
	width: 100%;
}

@media (max-width: 768px) {
	.user-home-page-container {
		background-image: none;
	}
}
