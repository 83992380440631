.react-datepicker-wrapper {
	display: block;
}

.react-datepicker {
	border-radius: 0.4rem;
	border: none;
	font-family: inherit;
}

.react-datepicker__header {
	border-top-left-radius: 0.4rem;
	border-top-right-radius: 0.4rem;
	background-color: #22418b;
	padding-top: 1rem;
}

.react-datepicker__header__dropdown--select {
	margin-top: 7px;
}

.react-datepicker__header__dropdown--select select {
	padding: 5px;
	border-radius: 0.2rem;
	-webkit-appearance: menulist;
	-moz-appearance: menulist;
	-o-appearance: menulist;
	outline-style: menulist;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
	color: #ffffff;
}

.react-datepicker__header .react-datepicker__day-name {
	color: #ffffff;
	user-select: none;
}

.react-datepicker__day {
	user-select: none;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
	background-color: #22418b;
	color: #ffffff;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
	background-color: #1a336c;
	color: #ffffff;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--previous:hover,
.react-datepicker__portal .react-datepicker__navigation--previous,
.react-datepicker__portal .react-datepicker__navigation--previous:hover {
	border-right-color: #ffffff;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--next:hover,
.react-datepicker__portal .react-datepicker__navigation--next,
.react-datepicker__portal .react-datepicker__navigation--next:hover {
	border-left-color: #ffffff;
}

.react-datepicker__navigation {
	top: 22px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before,
.react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
	border-top-color: #ced4da;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
	border-bottom-color: #22418b;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
	border-bottom-color: #22418b;
}

.react-datepicker__portal {
	background-color: rgba(0, 0, 0, 0.5);
}

.react-datepicker__close-icon {
    top: 18px;
    right: 12px;
    width: 25px;
    height: 25px;
    padding: 0px;
}

.react-datepicker__close-icon:active {
	outline-style: none;
}

.react-datepicker__close-icon::after {
	background-color: #22418b;
	color: #fff;
	height: 25px;
	width: 25px;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
	background-color: #22418b;
}
