.feed-show-div {
	background-color: white;
	width: 69px;
	height: 60px;
	box-shadow: 0px 0px 15px rgba(19, 21, 38, 0.1);
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	right: 0;
	top: 70px;
	z-index: 10;
}

.feed-show-div:hover {
	cursor: pointer;
}

.feed-icon {
	width: 16.5px;
	height: 17px;
}

.feed {
	width: 324px;
	height: 100%;
	background-color: white;
	position: absolute;
	right: -324px;
	transition: all 0.2s linear;
	top: 0;
	bottom: 0;
	z-index: 11;
}

.feed-visible {
	right: 0;
}

.hide-feedbar-arrow {
	position: absolute;
	top: 0;
	left: 0;
}

.hide-feedbar-arrow img {
	color: #040d6f;
}

@media (max-width: 1294px) {
	.feed-show-div {
		top: 333px;
	}
}

@media (max-width: 768px) {
	.feed-show-div {
		top: 330px;
	}
}

.feed-items-container {
	height: calc(100% - 73px);
	overflow: auto;
}

.feedbar-loading-dots {
	width: 50px;
}
