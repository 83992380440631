.signup-process-container .signup-form-sidebar {
	background-color: #ffffff;
	font-weight: 600;
	width: 250px;
	min-width: 250px;
	max-width: 250px;
}

.signup-process-container .signup-form-container {
	height: calc(100vh - 60px);
	background-color: #f0f1f6;
	padding: 3rem 6rem;
	overflow: auto;
}

.signup-process-container .step {
	margin-bottom: 25px;
	color: #777777;
	font-size: 14px;
}

.signup-process-container .active-step {
	color: black;
}

.signup-process-container .step-dot {
	width: 0.65rem;
	margin-right: 0.2rem;
}

.signup-process-form {
	width: 360px;
}

.signup-process-form .form-header .main-form-label {
	font-weight: 600;
	font-size: 2.2rem;
	-webkit-text-stroke-width: 0.5px;
}

.signup-process-form .inputs-wrapper {
	max-width: 332px;
}
